import React, { useRef, useEffect, Fragment, useContext, useState } from 'react';
import appContext from '../Context/appContext';

import useAPI from '../NetworkCall/API';

import RecommendedProducts from './cart/RecommendedProducts';
import * as WEBSITES from '../constants/website';
import CartProductsWidgetItems from './CartProductsWidgetItems';
import ConcentrationLimitSection from './ConcentrationLimits/ConcentrationLimitSection';
import getCartType from '../helpers/getCartType';
import { ASAP, ASAP_AND_SCHEDULE } from '../constants/deliveryType';
import StorageService from '../services/storageService';
import { useUpsellProducts } from '../hooks/cart';
import useSegment from '../hooks/segment';
import { useDeliveryDetails } from '../hooks/app';

import ProductsUserMayLike from './ProductsUserMayLike';
import { isWishlistApplicable, isRecommendedCartUpsellApplicable } from '../constants/feature';

import RewardNotificationWrapper from './WalletWithPurchaseRewardNotification/RewardNotificationWrapper';

import WalletWithPurchaseRewardSection from './WalletWithPurchaseRewardSection';
import HappyHours from './HappyHours';
import InlineLoader from './InlineLoader';
import { useDispatch, useSelector } from 'react-redux';
import { setProductFetchInfo } from '../redux/slices/userSlice';
import { setAllCartProductsWidget, setCartWidgetLastAddedProduct, setUpdateProductsWidget } from '../redux/slices/cartSlice';
import toFixedFloat from '../helpers/toFixedFloat';
import { handleDiscountedPrize } from './CurrentOrderInfo';
import { useConfigData } from '../Context/ConfigProvider';



const CartProductsWidget = ({ goToCart, isLoading, setShowAllCartProductsWidget, showAllCartProductsWidget = false }) => {
  // const {
  //   // state: {
  //   //   cart: {
  //   //     cartData: { calculatedAmount, beforeTaxDiscountedAmount, cart_items: cartItems = [] } = {}
  //   //   }
  //   // },
  //   dispatch
  // } = useContext(appContext);

  const { calculatedAmount, totalBeforeDiscount, totalWithCannabisTax, beforeTaxDiscountedAmount, cart_items: cartItems = [] } = useSelector(state => state.cart?.cartData) || {}
  const dispatchRedux = useDispatch()

  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)

  const notifications = useSelector(state => state.modal.notifications)

  const [cartProducts, setCartProducts] = useState([]);

  const totalNotificationLength = notifications.length + notificationsOverlap.length;

  const { trackEvent } = useSegment();

  const zoneName = StorageService.getZoneLocalStorage();

  const {
    data: { asapEnabled, scheduleEnabled, happyHours, isWalletCreditWithPurchaseApplicable }
  } = useDeliveryDetails();
  const { is_enable: is_happy_hours_enable } = happyHours || {};

  const cartType = getCartType(cartItems);

  const isNotificationOn = notifications?.filter(item => item.type === 'productDetails') || [];
  const appConfig = useConfigData()
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    body.classList.add('hide-acsb-icon');
    return () => {
      if (!totalNotificationLength) {
        body.classList.remove('modal-open');
      }
      body.classList.remove('hide-acsb-icon');
    };
  }, [totalNotificationLength]);

  const ref = useRef(null);

  const {
    data: { upsellProducts }
  } = useUpsellProducts();

  const goToCheckout = () => {
    trackEvent('Checkout Clicked', { source: 'Cart widget', cartProducts });
    goToCart();
    setShowAllCartProductsWidget(false);
    // dispatch({ type: 'setProductFetchInfo', payload: { shouldFetchProduct: true } });
    dispatchRedux(setProductFetchInfo({ shouldFetchProduct: true }))
    // dispatch({ type: 'setAllCartProductsWidget', payload: false });
    dispatchRedux(setAllCartProductsWidget(false))
    // dispatch({ type: 'setUpdateProductsWidget', payload: false });
    dispatchRedux(setUpdateProductsWidget(false))
  };

  const hideCartWidget = () => {
    setShowAllCartProductsWidget(false);
    // trackEvent('Cart Widget Closed', { zone_name: zoneName, cartProducts });
    // dispatch({ type: 'setAllCartProductsWidget', payload: false });
    dispatchRedux(setAllCartProductsWidget(false))
    // dispatch({ type: 'setUpdateProductsWidget', payload: false });
    dispatchRedux(setUpdateProductsWidget(false))
    // dispatch({ type: 'setCartWidgetLastAddedProduct', payload: [] });
    dispatchRedux(setCartWidgetLastAddedProduct([]))
  };

  useEffect(() => {
    const listener = event => {
      if (totalNotificationLength && !isNotificationOn?.length) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        setShowAllCartProductsWidget(false);
        trackEvent('Cart Widget Closed', { zone_name: zoneName, cartProducts });
        // dispatch({ type: 'setAllCartProductsWidget', payload: false });
        dispatchRedux(setAllCartProductsWidget(false))
        // dispatch({ type: 'setUpdateProductsWidget', payload: false });
        dispatchRedux(setUpdateProductsWidget(false))
        // dispatch({ type: 'setCartWidgetLastAddedProduct', payload: [] });
        dispatchRedux(setCartWidgetLastAddedProduct([]))
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, totalNotificationLength]);

  return (
    <Fragment>
      <div
        className={
          process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ||
            process.env.NEXT_PUBLIC_APP === WEBSITES.GOVANA ||
            process.env.NEXT_PUBLIC_APP === WEBSITES.GETHERB ||
            process.env.NEXT_PUBLIC_DEFAULT_SITES
            ? 'cart-widget-parent container'
            : 'cart-widget-parent container-fluid cart-widget-parent-dtc'
        }
      >
        <div className="c-backdrop" />
        <div className="cart-box" ref={ref}>
          <div className="c-header  d-flex justify-content-between ">
            <div className="d-flex align-items-center">
              <i className="icon-cart-outline-2 mr-2 default-l-primary c-icon popup-title-icon" />
              {cartItems?.length && scheduleEnabled && asapEnabled ? (
                <>
                  <span className="align-self-center bold mr-2">Delivery Type:</span>

                  <span className="c-text align-self-center bold">
                    {cartType === ASAP_AND_SCHEDULE ? 'ASAP or Scheduled' : cartType === ASAP ? 'ASAP' : 'Scheduled'}
                  </span>
                </>
              ) : (
                <span className="c-text align-self-center bold">Cart</span>
              )}
            </div>
            <button data-testid="#close-cart-widget" type="button" className="btn" onClick={hideCartWidget}>
              <i className="icon-close" />
            </button>
          </div>
          {is_happy_hours_enable ? (
            <div className="hh-cartwidget">
              <HappyHours showText={false} />
            </div>
          ) : null}
          <ConcentrationLimitSection />
          <div className="c-list mt-3 added-rewards-present wallet-with-purchase-rewards-present">
            <div className="mb-3"><CartProductsWidgetItems from="widget" /></div>
            {isWalletCreditWithPurchaseApplicable && cartItems?.length ? <WalletWithPurchaseRewardSection from="widget" /> : null}

            {isRecommendedCartUpsellApplicable && cartItems?.length ? <hr /> : upsellProducts?.length ? <hr /> : null}

            {isWishlistApplicable ? <ProductsUserMayLike className="" isLoading={isLoading} showAllCartProductsWidget={false} /> : null}

            {/* {cartItems?.length && !isRecommendedCartUpsellApplicable ? <RecommendedProducts className="padding-15-side two-rem-mt cart-widget-reco-products" isLoading={isLoading} showAllCartProductsWidget={showAllCartProductsWidget} /> : null} */}

            <div className="c-spacer" style={{ height: 150 }} />
          </div>
          {isWalletCreditWithPurchaseApplicable && cartItems?.length ? <RewardNotificationWrapper /> : null}


          {cartItems.length ? (
            <div className="bottom-section padding-15-side has-box-shadow-top d-flex align-items-center justify-content-between">
              <div className="cart-total d-flex bold flex-column" test-id="#cart_widget_total">
                <div className="price-container default-l-primary text-primary">
                  <span style={{ color: '#868686', fontWeight: '300' }} className='old-price .fw-200'>{handleDiscountedPrize(totalBeforeDiscount, calculatedAmount)}</span>
                  {
                    appConfig.DISPLAY_TAXED_PRICE ?
                      `$${totalWithCannabisTax || 0.00}`
                      :
                      beforeTaxDiscountedAmount
                        ? `$${parseFloat(beforeTaxDiscountedAmount).toFixed(2)}`
                        : calculatedAmount
                          ? `$${parseFloat(calculatedAmount).toFixed(2)}`
                          : '$0.00'}
                  <span className='text-[11px] !text-[#868686] font-[400] ml-2'>{appConfig.DISPLAY_TAXED_PRICE ? `(Tax Incl.)` : ``}</span>
                </div>

                <div className="total-text">
                  <p className="text-left mb-0 text-tax">Net Subtotal</p>
                </div>

                <div className="text-tax">
                  <small className="sm-text" test-id="#cart_widget_taxes_text">
                    {appConfig.DISPLAY_TAXED_PRICE ? "Discounts calculated at checkout." : "Taxes and discounts calculated at checkout."}
                  </small>
                </div>
              </div>

              <div className="text-center">
                <button
                  data-testid="#checkout-button"
                  className="btn btn-primary checkout-btn p-2 justify-content-between"
                  type="button"
                  onClick={goToCheckout}
                >
                  Checkout
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>


    </Fragment>
  );
};

export default CartProductsWidget;
